.welcome {
  width: 100%;
  max-width: var(--max-width);
  padding: 4.375rem 3.375rem;

  ul {
    margin-left: 1rem;
  }

  // Welcome text
  >div:first-child {
    font-style: normal;

    h1 {
      font-weight: 600;
      font-size: 35.43px;
      // line-height: 47px;
      height: 2.25rem;
      letter-spacing: -0.02em;
      text-transform: uppercase;
    }

    // Welcome promo
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 23.3758px;
      line-height: 45px;
      text-transform: uppercase;
    }
  }

  // Search feature box
  >div:last-child {
    h3 {
      color: var(--grey-100);
      text-transform: uppercase;
      font-size: 16px;
      margin-bottom: 1rem;
    }

    button {
      margin-left: 2rem;
    }
  }

  // Promo text
  .promoText {
    margin-top: 1.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */
    letter-spacing: 0.5px;

    color: var(--grey-300);
  }
}