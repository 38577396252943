.component {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
  background: #FFFFFF;
  width: 100%;

  .article {
    position: relative;
    height: 600px;
    overflow: hidden;
    display: flex;
    background: linear-gradient(270deg, #E0E0E0 0%, #F7F7F7 100%);

    img {
      position: absolute;
      top: 8rem;
      left: -30%;
      z-index: 1;
      mix-blend-mode: multiply;
    }

    .buttons {
      display: flex;
      gap: 1.25rem;
      margin-top: 1.25rem;
    }

    .banner {
      position: absolute;
      left: 3rem;
      width: 2.25rem;
      z-index: 2;
    }

    .image {
      width: 50%;
    }

    .info {
      z-index: 2;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 2.375rem;
      text-transform: uppercase;

      
      .type {
        font-size: 24.9px;
        line-height: 19px;
        font-weight: 700;
        text-align: right;
        letter-spacing: 1px;
        color: #8C8C8C;
      }

      h2 {
        font-size: 24.9px;
        line-height: 19px;

        &.brand {
          margin-top: .8125rem;
          font-weight: 900;
          text-align: right;
          color: #000000;
        }
      }

      h3 {
        font-weight: 700;
        font-size: 15.6px;
        margin-top: 10px;
        text-align: right;
        text-transform: uppercase;
        color: #000000;
      }

      p {
        border-top: 1px solid #868686;
        padding-top: 11px;
        margin: 1.25rem 0;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        /* or 133% */

        letter-spacing: 0.5px;
        text-transform: uppercase;

        color: #000;
      }
    }

    .price {
      .currentPrice {
        display: flex;
        font-weight: 600;
        font-size: 2.5rem;
        letter-spacing: -0.758887px;
        color: #000;
        font-style: italic;

        span {
          font-style: italic;
          font-weight: 600;
          font-size: 1rem;
          // line-height: 21px;
          letter-spacing: 1.01454px;
          margin-top: .375rem;
          margin-left: .75rem;
        }
      }

      .regularPrice {
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
        color: #7C7C7C;
      }
    }

    &:last-child {
      flex-direction: row-reverse;
      background: linear-gradient(90deg, #E0E0E0 0%, #F7F7F7 100%);

      .info {
        align-items: flex-start;

        h1 {
          text-align: left;
        }

        p {
          text-align: left;
        }
      }


      img {
        left: unset;
        right: -24%;
      }

      .banner {
        right: 3rem;
        left: unset;
      }
    }
  }

  @media screen and (max-width: 769px) {
    grid-template-columns: 1fr;
    background-color: unset;
    gap: .8125rem;
    padding: 1rem var(--mobile-page-padding);

    .article {
      border-radius: .5rem;
      height: 100%;
      width: 100%;
      aspect-ratio: 1;

      .info {
        margin: 1.1875rem;
        width: 100%;

        p {
          width: 50%;
          margin: .5rem 0;
        }
      }

      .image {
        width: 0%;
      }

      .price {
        .currentPrice {
          font-size: 34.1223px;
        }
      }

      .buttons {
        justify-content: space-between;
        width: 100%;
      }

      img {
        height: auto;
        top: 10%;
        left: -35%;
        width: 80%;
      }

      &:last-child {
        img {
          right: -35%;
        }
      }
    }
  }
}