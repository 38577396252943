.component {
  align-items: center;
  background-color: #ffffff;
  border-color: transparent;
  display: flex;
  width: 190px;
  border-radius: 6px;
  margin-left: 1px;

  .block {
    background-color: #264963;
    height: 2.5rem;
    width: 30px;
    border-radius: 6px 0 0 6px;
    margin-left: -1px;
  }

  .selected {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
  }

  input {
    color: #4a4a4a;
    font-family: "Spezia Complete Upright-NarrowSemiBold", Helvetica;
    font-size: 30.4px;
    font-weight: 600;
    letter-spacing: 0.91px;
    line-height: 6.2px;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    border: none;
    margin: 0 6px;
    text-transform: uppercase;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #9b9b9b;
    }
  }
}