//import variables
@import '../../../styles/variables';



.container {
  width: 100%;
  max-width: var(--max-width);
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: $screen-sm-max) {
    grid-template-columns: repeat(2, 1fr);
  }

  .category {
    //max-height: 17.875rem;
    max-height: 22rem;
    background-color: var(--grey-100);
    position: relative;
    filter: grayscale(100%);

    &:hover,
    &.active {
      cursor: pointer;
      background: linear-gradient(180deg, #f7a623 0%, #ef7e2e 100%);
      filter: grayscale(0%);
    }

    img {
      width: 100%;
      height: auto;

      // temp
      margin-top: -2rem;
    }

    span {
      bottom: 2.25rem;
      position: absolute;
      font-style: italic;
      font-weight: 800;
      font-size: 25px;
      line-height: 35px;
      width: 100%;

      display: flex;
      align-items: flex-end;
      justify-content: center;
      letter-spacing: -0.015em;
      text-transform: uppercase;
    }
  }

  @media (max-width: $screen-sm-max) {
    .category {
      max-height: unset;
      aspect-ratio: 1 / 1;
      background: linear-gradient(180deg, #f7a623 0%, #ef7e2e 100%);
      filter: grayscale(0%);

      img {
        margin-top: -1rem;
      }

      span {
        font-size: 16px;
        bottom: 0.5rem;
      }
    }
  }
}