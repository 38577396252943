.container {
  height: auto;
  max-height: 31.375rem;
  width: 100%;
  max-width: var(--max-width);
  position: relative;
  overflow: hidden;

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 31.5164px;
    line-height: 35px;
  }

  @media screen and (max-width: 970px) {
    // overflow: hidden;
    // margin-top: 2rem;
    // height: auto;

    img {
      height: auto;
      width: 150%;
      margin-left: -25%;
    }

    .heroText {
      display: none;
    }
  }

  .heroText {
    position: absolute;
    top: 2.5rem;
    left: 3rem;
    width: 28rem;
    text-transform: uppercase;
  }
}