.component {
  position: relative;
  height: 6rem;
  font-style: italic;
  z-index: 1;

  div {
    position: absolute;
    width: 5.5rem;
    height: 34px;
    top: 26px;
    left: -27px;
    font-weight: 900;
    font-size: 17.8899px;
    line-height: 19px;

    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.025em;
    text-transform: uppercase;

    color: #FFFFFF;

    transform: rotate(90deg);
  }
}