.component {
  background: linear-gradient(180deg, #F59E26 0%, #F0832E 100%);
  box-shadow: 0px 7.75815px 15.5163px rgba(0, 0, 0, 0.1);
  border-radius: 31.0326px;

  height: 32px;
  width: 32px;
  border: 0;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    svg {
      transform: rotate(0);
      margin-right: 2px;
    }
  }

  &.right {
    svg {
      transform: rotate(180deg);
      margin-left: 2px;
    }
  }
}