.component {
  height: 413px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 970px) {
    display: flex;
    justify-content: flex-start;
    height: unset;

    img {
      width: auto;
      height: 380px;
    }
  }

  @media screen and (max-width: 769px) {
    img {
      height: 250px;
    }
  }

  @media screen and (max-width: 500px) {
    img {
      align-self: flex-start;
      margin-left: -100px;
    }
  }

  section {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: flex;
    flex-direction: column;
    margin-right: 6.25rem;

    @media screen and (max-width: 970px) {
      // justify-content: flex-end;
      margin-right: 1rem;
    }

    h2 {
      width: 20rem;
      font-size: 49.6477px;
      line-height: 54px;
      text-align: right;
      letter-spacing: 0.025em;
      text-transform: uppercase;

      @media screen and (max-width: 970px) {
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        text-align: right;
      }
    }

    span {
      font-size: 20.5492px;
      line-height: 30px;
      text-align: right;
      padding-right: 0.125rem;

      @media screen and (max-width: 970px) {
        font-size: 14px;
      }
    }
  }
}