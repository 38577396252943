.component {
  position: relative;
  background: linear-gradient(180deg, #2B2B2B 0%, #070707 100%);
  overflow: hidden;
  height: 671px;

  h2 {
    padding-top: 6.25rem;
    font-style: italic;
    font-weight: 700;
    font-size: 27.2401px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;

    background: linear-gradient(180deg, #FFFFFF 0%, #636363 100%);
    background-clip: text;
  }

  .button {
    z-index: 800;
    position: absolute;
    top: 50%;
    // transform: translateY(-50%);

    &.left {
      left: 2rem;
    }

    &.right {
      right: 2rem;
    }
  }

  .posts {
    margin-top: 2.5rem;
    display: flex;
    flex-wrap: nowrap;
    // position: absolute;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    //grid-template-columns: repeat(3, 1fr);

    .group {
      display: flex;
      width: 100%;
      gap: 19px;
      justify-content: center;
    }
  }

  .post {
    width: 333px;
    height: 333px;
    // aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .image {
      // border-radius: 50%;
      object-fit: cover;
      object-position: center center;
      border-radius: 15px;
      width: 100%;
      height: 100%;

      // on hover animate cubic-bezier
      transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.6s ease;
      will-change: transform;
      object-fit: cover;
      object-position: center center;
      border-radius: 0px;
    }

    .caption {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 100%;
      padding: 2.5rem;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 13.9647px;
      line-height: 20px;
      letter-spacing: -0.387908px;
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.6s ease;

      // elipsis
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      .caption {
        opacity: 1;
      }

      .image {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 970px) {
    height: 550px;

    .button {
      top: 54%;
    }

    .post {
      max-width: 250px;
      max-height: 250px;
      width: calc(100% - 12rem);
      height: auto;
      aspect-ratio: 1/1;
    }
  }
}